@use 'variables' as var;

.logo{
    width: var.$logo-big-width;
    height: var.$logo-big-height;
}
.logo-small{
    width: calc(var.$logo-big-width / 2);
    height: calc(var.$logo-big-height / 2);
}

body, #root, .App{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.page {
    flex: 1;
}

.form-controller{
    padding-top: var.$small-gap;
}

.MuiDataGrid-columnHeaders{
    background: rgb(234, 240, 246) !important;
}