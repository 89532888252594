.logo {
  width: 360px;
  height: 240px;
}

.logo-small {
  width: 180px;
  height: 120px;
}

body, #root, .App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  flex: 1;
}

.form-controller {
  padding-top: 1rem;
}
.MuiDataGrid-columnHeaders{
  background: rgb(234, 240, 246) !important;
}
.MuiMenuItem-gutters{
  color: #000 !important;
}
