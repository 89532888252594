$primary-color: #2EAAFA;
$secondary-color: #1F2F98;

$logo-width: 120px;
$logo-height: 80px;

$logo-big-width: $logo-width * 3;
$logo-big-height: $logo-height * 3;

$gap: 1.2rem;
$small-gap: calc($gap / 1.2);